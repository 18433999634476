@import '~antd/dist/antd.css';

.ant-layout {
    min-height: 100vh;
}

.ant-layout-sider {
    background-image: linear-gradient(#1e2422, #000000);
    background-attachment:fixed;
    width: 800px;
    padding: 30px;
}

.ant-layout-content {
    background-image: linear-gradient(#494B4A, #030404);
    background-attachment:fixed;
}

.ant-layout-footer {
    background-color: #292929;
    padding-bottom: 4px;
    padding-top: 15px;
}

.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab-btn:focus {
    color: white;
}

.ant-tabs-tab {
    color: rgb(125, 125, 125);
    font-size: 18px;
    letter-spacing: 2px;
}

.ant-tabs-tab:hover {
    color: white;
}

/* On screens that are more than 700px, add ~300px additional margin to make space for sidebar */
@media screen and (min-width: 700px) {
    .ant-tabs-nav-list {
        margin-left: 285px;
    }
}

.ant-tabs-ink-bar {
    background: #1db6b9;
    width: 10px;
}

.ant-slider-track {
    background-color: #1DB954;
}

.ant-slider:hover .ant-slider-track {
    background-color: #1DB954;
}

.ant-slider-handle {
    border: 0px
}

.ant-slider-rail {
    background-color: #3E3E3E;
}

.logo {
    width:50px;
    margin-bottom: 20px;
  }
  
  .searchBar {
    border-top: 2px solid rgb(125, 125, 125);
    border-bottom: 2px solid rgb(125, 125, 125);
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
/* The side navigation menu */
.sideBar {
  margin: 0;
  padding: 0;
  width: 300px;
    background-image: linear-gradient(#1e2422, #000000);
    background-attachment:fixed;
  position: fixed;
  height: 100%;
  overflow: auto;
}

/* Sidebar links */
.sideBar div {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

/* Active/current link */
.sideBar a.active {
  background-color: #04AA6D;
  color: white;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 300px;
  padding: 1px 16px;
  height: 1000px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  .sideBar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sideBar div {float: left;}
  div.content {margin-left: 0;}
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar div {
    text-align: center;
    float: none;
  }
}
  
  .recentPlayed {
    border-top: 2px solid rgb(125, 125, 125);
    border-bottom: 2px solid rgb(125, 125, 125);
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .recentTitle {
    color: white;
    font-size: 18px;
    font-weight: 400;
  }
  
  .install {
    display: flex;
    justify-content: space-between;
  }
  
  .contentWindow {
    padding: 5px 0px;
  }

  .footer {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items:center;
    background-color: #292929;
    color: rgb(205, 203, 203) !important; 
  }

/* On screens that are more than 700px, add ~300px additional margin to make space for sidebar */
@media screen and (max-width: 700px) {
  .footer {
    position: relative;
    bottom: 0;
    display: inline-block;
    justify-content: space-between;
    align-items:center;
    background-color: #292929;
    color: rgb(205, 203, 203) !important; 
  }
}

.walletlink {
  margin: 0;
  padding:  0;
  display: inline;
}




    