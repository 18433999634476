.featuredTitle {
  color: white;
  font-size: 35px;
  font-weight: 600;
  justify-content: center;
  display: flex;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 30px;
}

/* On screens that are more than 700px, add ~300px additional margin to make space for sidebar */
@media screen and (min-width: 700px) {
    .featuredTitle {
      color: white;
      font-size: 35px;
      font-weight: 600;
      justify-content: center;
      display: flex;
      letter-spacing: 2px;
      margin-top: 20px;
      margin-bottom: 30px;
      margin-left: 285px;
    }
}

.albums {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  gap: 20px;
  row-gap: 50px;
  -webkit-box-pack: start;
}

/* On screens that are more than 700px, add 300px additional margin to make space for sidebar */
@media screen and (min-width: 700px) {
    .albums {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 1000px;
      width: 100%;
      gap: 20px;
      row-gap: 50px;
      -webkit-box-pack: start;
      padding-left: 285px;
    }
}

.albumSelection {
  color:rgb(205, 203, 203);
  text-align: center;
  transition: transform .4s;
}

.albumSelection:hover {
  color: white;
  transform: scale(1.05);
}
